* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  line-height: 1.3;
}

/*style nav bar */
.nav ul {
  list-style: none;
  overflow: hidden;
  opacity: .7;
}

.nav li {
  float: left;
}

.nav li a {
  display: block;
  color: #333;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;

}

body {
  font-family: sans-serif;
}

li a:hover {
  color: white;
  opacity: 1;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.nav {
  /* opacity: 0; */
  position: absolute;
  z-index: 1;
  width: 100%;
}

.right-nav {
  float: right;
}

.me-nav {
  cursor: default;
}



/* traits animation */
.fade-in-image {
  animation: fadeIn ease 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* traits */
.traits {
  font-size: 2em;
}

#trait {
  opacity: 0;
}

.parent-container {
  padding: 1rem;
  max-width: 600px;
  margin: auto;
}



/* skills container */
.skills {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  padding: 10px;
  max-width: 600px;
  margin: 10px auto;
  gap: 15px;
  background-color: white;
  border-radius: 8px;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.skills-container {
  background-color: #F7F7F7;
  padding: 1rem;
  margin: auto;
}

.skills-title {
  padding: 1rem;
  max-width: 600px;
  margin: auto;
}


/* cets container */
.certs-container {
  padding: 1rem;
  max-width: 900px;
  margin: auto;
}

.outter-cert-container {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  flex-wrap: wrap;
  overflow: auto;
}

.cert-container {
  justify-content: center;
  padding: 10px;
  margin: 10px;
  max-width: 400px;
  display: flex;
  background-color: white;
  border-radius: 8px;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}


.cert {
  font-size: 20px;
  border-bottom: black solid 2px;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.button {
  cursor: pointer;
  opacity: .5;
}

.button-link {
  cursor: pointer;
  opacity: .5;
  width: 3em;
  height: auto;
}

.button-link:hover {
  opacity: 1;
}

.button:hover {
  opacity: 1;
}


/* project container */
.project-container {
  padding: 10px;
  border-radius: 8px;
  --tw-shadow: 0 13px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.projects-container {
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: repeat(2, 1.2fr); */
  column-gap: 20px;
  row-gap: 20px;
  max-width: 800px;
  margin: auto;
}

.project-name{
  font-weight: normal;
}

.title{
  padding-bottom: 10px;
}

.description {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-top:10px;
  padding: 5px;
}



.text {
  padding-top: 10px;
  max-width: 600px;
  margin: auto;
}

/* about section */
.about-icons {
  display: flex;
  gap: 10px;
  max-width: 600px;
  padding-top: 1rem;
}

h1 {
  font-weight: 300;
  letter-spacing: 2px;
}

p {
  letter-spacing: 1px;
  font-size: 1rem;
  color: #333333;
}


/* fix for phone */
@media screen and (max-width: 900px) {
  .icon {
    width: 50%;
  }
  h1 {
    font-size: 24px;
  }
}




/*waves css*/
.name {
  font-weight: 800;
  font-size: 1.5rem;
  font-weight: bold;
  font-size: 48px;
}

@import url(//fonts.googleapis.com/css?family=Lato:300:400);


.header {
  position: relative;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: radial-gradient(circle,
      rgba(47, 128, 237, 1) 0%,
      rgba(178, 255, 218, 1) 100%);
  color: white;
}

.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  flex-direction: column;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
/* @media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
} */